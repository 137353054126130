.justyfy-comment-section {
  display: flex;
  justify-content: space-between;
}
#id-for-last-bg {
  width: 350px;
  height: 40px;
}
@media screen and (max-width: 540px) {
  .justyfy-comment-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  #id-for-last-bg {
    width: 100%;
  }
}
