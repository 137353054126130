.page-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.Contact-span-flexed {
  display: flex;
  gap: 0.5rem;
}
.Contact-span-flexed span {
  font-size: 16px;
  font-weight: 600;
}
.hr-tag-class {
  border: 1px solid gray !important;
  width: 100% !important;
}

.pending-here-status {
  width: 80px;
  height: 40px;
  background-color: #6c757d;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
}
.Category-product {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
#all-set-Sr-no {
  width: 500px !important;
}
.three-ones {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.action-more-css {
  display: flex;
  justify-content: center;
}
.both-Next-Prev {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
table,
th,
td {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}
.td-first-clums {
  width: 160px;
}
th,
td {
  padding: 10px;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
th {
  text-align: center;
}
.Name-change-status {
  width: 168px;
  height: 45px;
  outline: none;
  background-color: #fff;
  color: #0dcaf0;
  border: 1px solid #0dcaf0;
  border-radius: 0.25rem;
}
.Name-change-status:hover {
  background-color: #0dcaf0;
  transition: all ease 0.3s;
  color: #000;
}
.Add-button-cate {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 50px;
  width: 200px;
  border: 2px solid #008cff;
  outline: none;
  border-radius: 30px;
  font-size: 16px;
  background-color: #fff;
}
.Add-button-cate:hover {
  background-color: #008cff;
  color: #fff;
}
.list-of-products {
  font-weight: 500;
  line-height: 1.2;
  color: #32393f;
  width: 100%;
}
.table-responsive-add {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
    0 2px 6px 0 rgb(206 206 238 / 54%);
  width: 100%;
  padding: 1rem 1rem;
  height: 100%;
}
.add-bold-in-font {
  font-size: 15px;
  font-weight: bold;
}
.serch-beetwin-two {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.delete-buttons-red {
  width: 63px;
  height: 45px;
  border-radius: 0.25rem;
  border: 1px solid red;
  background-color: #fff;
}
.Page-button-click {
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
  gap: 2rem;
  align-items: center;
  border: none;
}
.Page-button-click button {
  width: 120px;
  height: 50px;
  border: none;
  outline: none;
  background-color: red;
  color: black;
  border-radius: 4px;
}
.delete-btn-red {
  width: 22px;
  height: 22px;
}
.delete-btn-red:hover {
  color: #fff;
}

.easy-to-spece {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.responsive-design {
  height: 75px;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  background-image: linear-gradient(
    var(--bs-table-accent-bg),
    var(--bs-table-accent-bg)
  );
  border-bottom-width: 1px;
}
@media screen and (max-width: 1920px) {
  .page-content {
    justify-content: flex-start;
    display: flex;
  }
  .grid-Sub-category-tran {
    grid-template-columns: repeat(5, 1fr);
    display: grid;
  }
}
@media screen and (max-width: 884px) {
  .new-add-important-flex {
    flex-direction: column;
  }
}
@media screen and (max-width: 540px) {
  .responsive-time-here {
    width: 100px;
    height: 100px;
  }
  .responsive-design {
    height: 100px;
  }
  .sidebar-wrapper {
    box-shadow: 0 2px 6px 0 rgba(255, 255, 255, 0.65),
      0 2px 6px 0 rgba(255, 255, 255, 0.54);
  }
  .Add-button-cate {
    width: 250px;
  }
  .table-responsive-add {
    box-shadow: 0 2px 6px 0 rgba(255, 255, 255, 0.65),
      0 2px 6px 0 rgba(255, 255, 255, 0.54);
    padding: 0;
  }
  .easy-add-scroll-new {
    overflow-y: scroll;
  }
  .serch-beetwin-two {
    flex-direction: column;
    gap: 2rem;
  }
  .new-add-important-flex {
    flex-direction: column;
    display: flex;
  }
}
@media screen and (max-width: 540px) {
  .Category-product {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
    margin-left: 2rem;
  }
  .page-content {
    margin-left: 2rem;
  }
}
