.name-api-flex {
  display: flex;
  gap: 3rem;
  align-items: center;
}
.two-big-image-commnet {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.img-comments-map {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
#some-id-for-detailsed {
  width: 30%;
  padding: 1rem;
}
#sevnty-percent-detail-view {
  width: 70%;
}
.css-view-detail-profile {
  gap: 2rem;
  display: flex;
  align-items: center;
  width: 100%;
}
.hr-detail-post-def {
  height: 400px;
  width: 1px;
  border: 1px solid grey;
}
.Liste-flex-post-detail {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.diff-img-detail-for {
  width: 330px;
  height: 330px;
  margin-left: 2rem;
}
#first-card-css-re {
  box-shadow: 0 10px 20px rgba(46, 35, 94, 0.1),
    0 6px 6px rgba(46, 35, 94, 0.15);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  height: 450px;
  width: 40%;
  padding: 1.5rem;
}
#secound-card-css-re {
  box-shadow: 0 10px 20px rgba(46, 35, 94, 0.1),
    0 6px 6px rgba(46, 35, 94, 0.15);
  width: 70%;
  height: 450px;
  padding: 1.5rem;
}
.hr-redesign-postviews {
  width: 100%;
  border: 2px solid grey;
}
.count-detail-for-apis {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.comments-flex-all-heres {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.two-hr-div-post {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.two-hr-div-post span {
  font-size: 20px;
  font-weight: 600;
}
.post-repost-detail-def {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.Two-title-Post-list {
  display: flex;
  gap: 0.5rem;
}
.reason-message-flex-map {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.view-post-listed {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
@media screen and (max-width: 1280px) {
  .diff-img-detail-for {
    width: 90%;
    height: 250px;
  }
  #some-id-for-detailsed {
    width: 40%;
  }
}
@media screen and (max-width: 540px) {
  .css-view-detail-profile {
    flex-direction: column;
  }
  #sevnty-percent-detail-view {
    width: 90%;
    margin-top: 0 !important;
  }
  #some-id-for-detailsed {
    width: 90%;
  }
  #first-card-css-re {
    justify-content: center;
    width: 90%;
  }
  #secound-card-css-re {
    width: 90%;
  }

  .two-big-image-commnet {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1rem;
    justify-content: center;
  }
  .diff-img-detail-for {
    width: 250px;
    height: 250px;
  }
  .hr-detail-post-def {
    height: 2px;
    width: 100%;
  }
}
@media screen and (max-width: 360px), (max-width: 375px) {
  .two-big-image-commnet {
    align-items: flex-start;
    margin-left: 0;
  }
  .diff-img-detail-for,
  .delete-buttons-red {
    margin-left: 0;
  }
}
