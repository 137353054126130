.New-add-Categories {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.New-add-Categories span {
  font-weight: 500;
  color: black;
  font-size: 18px;
}
.page-content-add {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem;
  /* margin-top: 2.5rem;
    margin-left: 2rem; */
}
.grid-class-in-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}
.form-flex-adde {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.create-select-img-main {
  display: flex;
  /* flex-direction: column; */
  gap: 1rem;
  margin-top: 3rem;
}
.add-Create-button {
  width: 150px;
  height: 50px;
  border: none;
  outline: none;
  background-color: #037de2;
  border-radius: 5px;
  margin-top: 2rem;
  color: #fff;
}
.image-container {
  width: 160px;
  height: 160px;
  border: 2px solid black;
}
.select-img-here {
  width: 160px;
  height: 160px;
}
@media screen and (max-width: 540px) {
  .page-content-add {
    margin-left: 0;
    margin-top: 0;
  }
  .grid-class-in-form {
    flex-direction: column;
    display: flex;
  }
  .form-control {
    width: 300px !important;
  }
  .create-select-img-main {
    flex-direction: column;
  }
  .New-add-Categories {
    flex-wrap: wrap;
    gap: 1rem;
  }
}
/*  AddLanuague css*/
.two-diffrent-api {
  display: flex;
  gap: 2rem;
}
