.span-delete-heres {
  font-size: 22px;
  font-weight: 500;
  margin-left: 1rem;
}
.flex-delete-popup {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
