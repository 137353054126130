.Main-Content-class {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 80%;
}
.four-card-products {
  display: flex;
  gap: 2rem;
  width: 100%;
  margin-top: 2rem;
  align-items: center;
}
.all-btn-texts {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.name-post-span {
  font-size: 18px;
  font-weight: 600;
}
.span-all-btn-texts {
  font-size: 15px;
  font-weight: 600;
}
.span-delete-sure-you {
  font-size: 15px;
  font-weight: 400;
}
.def-border-post {
  border: 1px solid rgb(75, 75, 75);
  height: 2px;
  width: 80%;
}
.post-want-flex-yes {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}
.all-btn-texts button {
  width: 80px;
  height: 40px;
  background-color: red;
  color: #fff;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
}
.post-want-flex-yes button {
  width: 80px;
  height: 50px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 600;
}
.flex-delete-popup-refunds {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.img-popu-heres {
  width: 60px;
  height: 60px;
  /* width: 20%;
  aspect-ratio: 3/3;
  object-fit: fill; */
  border-radius: 10px;
  border: 1px solid #e6e6e6;
}
.Two-img-popu-heres {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid red;
}
.box-border-products {
  cursor: pointer;
  height: 200px;
  width: 350px;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 206);
  border-bottom: 3px solid #008cff;
}
.title-products {
  color: #008cff;
  font-size: 22px;
  font-weight: 500;
}
.number-def {
  font-size: 16px;
  line-height: 1.2;
  color: #32393f;
  font-weight: bold;
}
.blue-inbox-data {
  gap: 0.3rem;
  display: flex;
  flex-direction: column;
  color: #008cff;
  background-color: rgba(0, 140, 255, 0.18);
  border-color: rgb(209, 234, 255);
  padding: 0.375rem, 0.75rem !important;
  text-decoration: none;
  align-items: center;
  width: 90px;
  height: 60px;
  justify-content: center;
  border-radius: 0.25rem;
}
th {
  font-weight: bold !important;
}
.blue-inbox-data:focus,
.blue-inbox-data:active {
  border-color: #abd8ff;
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.blue-inbox-data > span {
  font-weight: 500;
}
.two-inbox-blue-data:focus,
.two-inbox-blue-data:active {
  border-color: #abd8ff;
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.two-inbox-blue-data {
  color: #fff;
  background-color: #008cff;
  border-color: #008cff;
  text-decoration: none;
  align-items: center;
  width: 90px;
  height: 60px;
  justify-content: center;
  border-radius: 0.25rem;
  gap: 0.3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 1920px) {
  .Main-Content-class {
    width: 100%;
  }
  .box-border-products {
    width: 380px;
    height: 220px;
  }
}

@media screen and (max-width: 1600px) {
  .Main-Content-class {
    width: 100%;
  }
  .box-border-products {
    height: 200px;
    width: 300px;
  }
}
@media screen and (max-width: 1440px) {
  .box-border-products {
    width: 500px;
    height: 220px;
  }
  .topbar .navbar {
    width: 80%;
  }
}
@media screen and (max-width: 1280px) {
  .four-card-products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 3rem;
    place-items: center;
  }
  .box-border-products {
    width: 400px;
  }
  .topbar .navbar {
    width: 100%;
  }
}
@media screen and (max-width: 884px) {
  .four-card-products {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .Main-Content-class {
    margin-left: 0;
  }
  .Main-Content-class {
    width: 100%;
  }
  .sidebar-wrapper {
    flex-direction: column;
    box-shadow: 0 2px 6px 0 rgb(255 255 255 / 65%),
      0 2px 6px 0 rgb(206 206 238 / 54%) !important;
    width: 100%;
  }
}
@media screen and (max-width: 778px) {
  .easy-add-scroll-new {
    overflow-y: scroll;
  }
  .sidebar-wrapper {
    flex-direction: column;
    box-shadow: 0 2px 6px 0 rgb(255 255 255 / 65%),
      0 2px 6px 0 rgb(206 206 238 / 54%) !important;
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .four-card-products {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
  }
  .four-card-products {
    margin-top: 2rem;
  }
  .all-btn-texts {
    margin-top: 1.5rem;
  }
  .box-border-products {
    width: 320px;
  }
}
@media screen and (max-width: 360px) {
  .box-border-products {
    width: 320px;
  }
  .Main-Content-class {
    margin-left: 0.5rem;
  }
}
