.Postdeatil-Main-Componenets {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 3rem;
}
.Onebox-Part-def {
  display: flex;
  width: 100%;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 350px;
  border: 3px solid #000;
  margin-top: 2rem;
  border-radius: 4px;
  padding: 1rem;
}
.same-gap-post {
  display: flex;
  gap: 0.8rem;
}
.image-and-delete {
  display: flex;
  align-items: flex-start;
  gap: 5rem;
  margin-left: 3rem;
}
.two-postion-here {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.delete-btns {
  width: 120px;
  height: 50px;
  border: none;
  background-color: red;
  color: #fff;
  border-radius: 4px;
}
.img-Post-Details {
  width: 100px;
  height: 100px;
}
.address-and-deatils {
  display: flex;
  flex-direction: column;
  margin-top: 0.6rem;
  gap: 0.5rem;
}
.address-and-deatils span {
  font-size: 16px;
  font-weight: 600;
}
.Post-Sub-Deatils {
  display: flex;
  gap: 1rem;
  width: 100%;
}
.posts-box-detail {
  display: flex;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 100%;
  border: 3px solid #201515;
  margin-top: 2rem;
  border-radius: 4px;
  padding: 1rem;
  flex-direction: column;
}
.detail-two-span-post span {
  font-size: 18px;
  font-weight: bold;
}
.Subcription-box-detail {
  display: flex;
  align-items: center;
  justify-items: center;
  width: 35%;
  height: 73vh;
  border: 3px solid #201515;
  margin-top: 2rem;
  border-radius: 4px;
  padding: 1rem;
}
.two-span-post {
  font-size: 30px;
  font-weight: 600;
  width: 90%;
}
.detail-two-span-post {
  font-size: 18px;
}
.layrout-detail {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}
.two-user-flex {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.hr-tag {
  border: 1px solid grey;
  height: 1px;
  width: 100%;
}
.line-post-Detail {
  height: 180px;
  width: 2px;
  border: 2px solid grey;
}

.Profile-card {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  height: 100%;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
    0 2px 6px 0 rgb(206 206 238 / 54%);
  justify-content: center;
}
.Two-differnt-Parts {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  width: 100%;
}
.radius-profile-pic {
  border-radius: 50%;
  border: 4px solid #008cff;
  width: 100px;
  height: 100px;
}
.Name-follw-main {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  align-items: center;
  margin-top: 2rem;
  justify-content: center;
}
.Name-follw-main > span {
  color: #6c757d;
  font-size: 16px;
  text-align: center;
}
.Doe-John-Name {
  font-size: 1.5rem;
}
.btn-follow-Name {
  width: 85px;
  height: 37px;
  background-color: #008cff;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
}
.Message-folloe-btn {
  width: 100px;
  height: 37px;
  background-color: #fff;
  border: 1px solid #008cff;
  outline: none;
  color: #008cff;
  border-radius: 5px;
}
.Message-folloe-btn:hover {
  background-color: #008cff;
  color: #fff;
}
.btn-main-components {
  display: flex;
  gap: 0.5rem;
}
.hr-tag {
  height: 1px;
  border: 0.8px solid grey;
  width: 100%;
}
.all-link-gaping {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
}
.span-item-Profile {
  font-size: 16px;
  color: #6c757d;
}
.Main-link-user-profile {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}
.social-icons {
  width: 25px;
  height: 25px;
}
.title-and-Name {
  display: flex;
  gap: 0.5rem;
}
.compilers-flex-data {
  display: flex;
  flex-direction: column;
}
.user-forms-Main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 750px;
  height: 442px;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
    0 2px 6px 0 rgb(206 206 238 / 54%);
  justify-content: center;
}
.form-first-flex {
  display: flex;
  width: 90%;
}
.Card-flex-add {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.form-control-flexable {
  display: flex;
  width: 680px;
  gap: 1rem;
  justify-content: space-between;
}
.rating-course-flex {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  width: 95%;
}
.rating-course-span {
  font-size: 22px;
  font-weight: 600;
}
.add-course-span {
  font-size: 16px;
  font-weight: 400;
}
.progress-main-def {
  width: 100%;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  height: 5px;
}
.perent-progress-bars {
  background-color: #008cff;
  width: 80%;
  border-radius: 0.25rem;
  height: 5px;
}
@media screen and (max-width: 1920px) {
  .user-forms-Main-div {
    width: 90%;
  }
  .compilers-flex-data {
    width: 100%;
  }
  .Two-differnt-Parts {
    width: 90%;
  }
}
@media screen and (max-width: 1660px) {
  .user-forms-Main-div {
    width: 100%;
    height: 442px;
  }
  .Two-differnt-Parts {
    width: 100%;
  }
}
@media screen and (max-width: 1440px) {
  .Two-differnt-Parts {
    width: 100%;
  }
}

@media screen and (max-width: 884px) {
  .form-control-flexable {
    width: 430px;
  }
  .user-forms-Main-div {
    width: 100%;
  }
  .Profile-card {
    width: 70%;
  }
}
@media screen and (max-width: 836px) {
  .form-control-flexable {
    width: 400px;
  }
}
@media screen and (max-width: 820px) {
  .form-control-flexable {
    width: 400px;
  }
}
@media screen and (max-width: 778px) {
  .form-control-flexable {
    width: 370px;
  }
}

@media screen and (max-width: 540px) {
  .Two-differnt-Parts {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
  }
  .Postdeatil-Main-Componenets {
    align-items: center;
    flex-direction: column;
  }
  .Profile-card {
    width: 90%;
  }
  .form-control-flexable {
    flex-direction: column;
    gap: 0rem;
  }
  .user-forms-Main-div {
    height: 500px;
  }
}
@media screen and (max-width: 360px) {
  .Two-differnt-Parts {
    margin-right: 0;
  }
  .form-control {
    width: 275px;
  }
}

@media screen and (max-width: 1280px) {
  .image-and-delete {
    gap: 2rem;
  }
}
@media screen and (max-width: 540px) {
  .Post-Sub-Deatils {
    flex-direction: column;
    align-items: center;
  }
  .posts-box-detail,
  .Subcription-box-detail {
    width: 90%;
  }
  .Onebox-Part-def {
    width: 90%;
    height: 100%;
  }
  .line-post-Detail {
    height: 0;
    display: none;
  }
  .image-and-delete {
    flex-direction: column;
  }
}
