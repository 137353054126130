.main-input-for-edits {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  width: 90%;
}
.gap-name-input-edit {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.title-add-langues{
  width: 850px;
}
.span-name-invester {
  font-size: 18px;
  font-weight: 400;
}
.main-Blos-for-edits{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}
.main-academy-for-edits{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 80%;
  align-items: flex-start;
  justify-content: flex-start;
}
.name-input-same-css {
  width: 450px;
  height: 40px;
  border-radius: 4px;
  outline: none;
  border-color: grey;
}
.update-btn-inexter {
  margin-top: 2rem;
  background-color: #008cff;
  width: 140px;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 4px;
  color: #fff;
}
.select-img-here {
  width: 155px;
  height: 155px;
}
.jodit-react-container{
  width: 850px;
}
.image-container.selected {
  border: none;
}
.image-container-secound--cate {
  width: 160px;
  height: 160px;
  border: 2px solid black;
}
.image-container {
  width: 160px;
  height: 160px;
  /* border: 2px solid black !important; */
  background-color: #000 !important;
}
@media screen and (max-width: 540px) {
  .title-add-langues{
    width: 350px !important;
  }
  .jodit-react-container {
    width: 350px !important;
  }
  .main-input-for-edits {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .name-input-same-css {
    width: 300px !important;
  }
  .main-Blos-for-edits {
    margin-left: 1rem;
  }
  .create-select-img-main{
    margin-left: 1rem;
  }
}
