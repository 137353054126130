.Container-Signup-Main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  user-select: none !important;
}
.form-error {
  color: red;
}
.Perent-Signups {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 4rem;
}
.bg-img-sign-up {
  background-image: url("https://admin.africamarket.today/public/assets//images/login-images/bg-login-img.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
}
.Perent-Signups > img {
  width: 230px;
}
.card-signup {
  width: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 206);
}
.card-perent {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}
.border-signup {
  border-radius: 0.25px;
  padding: 1.5rem;
  border: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.border-signup > h3 {
  color: #32393f;
  font-weight: 500;
  font-size: 1.75rem;
}
.index {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.form-control {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  display: block;
  width: 100%;
  padding: 0.5rem !important;
}
.input-group {
  display: flex;
  padding: 0.2rem;
  align-items: center;
  width: 100%;
  /* border: none; */
  border: 1px solid #ced4da;
  border-radius: 5px;
}
.border-signup > h3 {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.2;
  color: #32393f;
}
.Sign-up-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.two-part-sign {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
@media screen and (max-width: 884px) {
  .card-signup {
    width: 95%;
  }
}
@media screen and (max-width: 540px) {
  .card-signup {
    width: 100%;
  }
  .form-control {
    width: 100% !important;
  }
  .input-group {
    width: 100%;
    flex-wrap: nowrap !important;
  }
  .index {
    gap: 2rem;
  }
}
@media screen and (max-width: 360px), (max-width: 385px) {
  .card-signup {
    width: 90%;
  }
  .index {
    gap: 1rem;
  }
}
